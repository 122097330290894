import request from '@/utils/request'

//获取服务业数据
export function serviceHall(data) {
  return request({
    url: '/oms/portal/serviceHall',
    method: 'post',
    data
  })
}

//获取服务业数据详情
export function selectServiceReleaseById(data) {
  return request({
    url: `/oms/portal/selectServiceReleaseById?id=${data.id}`,
    method: 'post',
    // data
  })
}

//获取服务业数据
export function getServiceType(data) {
  return request({
    url: `/oms/portal/getServiceType?coreId=${data.coreId}`,
    method: 'post',
    // data
  })
}

//获取需求大厅数据
export function demandHall(data) {
  return request({
    url: '/oms/portal/demandHall',
    method: 'post',
    data
  })
}

//获取需求大厅数据详情
export function getDemandDetailVo(data) {
  return request({
    url: `/oms/portal/getDemandDetailVo?id=${data.id}&recommendPageNum=${data.recommendPageNum}`,
    method: 'post',
  })
}

//获取新闻资讯
export function articleList(data) {
  return request({
    url: '/oms/portal/articleList',
    method: 'post',
    data
  })
}

//获取新闻资讯数据详情
export function getArticleContent(data) {
  return request({
    url: `/oms/portal/getArticleContent?id=${data.id}`,
    method: 'post',
  })
}

//获取首页数据
export function homeList(data) {
  return request({
    url: '/oms/portal/index',
    method: 'post',
    data
  })
}

//省市先五级联动
export function getPlace(data) {
  let url = "/oms/portal/getPlace?"
  for (let key in data) {
    // if (data[key]) {
    url += `${key}=${data[key]}&`
    // }
  }
  url = url.substring(0, url.length - 1)
  return request({
    url,
    method: 'post',
    data
  })
}

//发送手机验证码
export function sendPhoneVeriCode(data) {
  return request({
    url: '/oms/portal/sendPhoneVeriCode',
    method: 'post',
    data
  })
}

//村集体新增
export function addVillage(data) {
  return request({
    url: '/oms/portal/addVillage',
    method: 'post',
    data
  })
}

//生产主体新增
export function addProductionOrganization(data) {
  return request({
    url: '/oms/portal/addProductionOrganization',
    method: 'post',
    data
  })
}

//服务字典数据
export function getDictData(data) {
  return request({
    url: `/oms/portal/getDictData?dictType=${data.dictType}`,
    method: 'post',
    data
  })
}

//服务组织新增
export function addOrganization(data) {
  return request({
    url: '/oms/portal/addOrganization',
    method: 'post',
    data
  })
}

//服务服务类型
export function getServiceTypes(data) {
  return request({
    url: '/oms/portal/getServiceTypes',
    method: 'post',
    data
  })
}

//获取组织目录数据
export function organizationList(data) {
  return request({
    url: '/oms/portal/organizationList',
    method: 'post',
    data
  })
}

//获取组织名录详情数据
export function getOrganizationVoByid(data) {
  return request({
    url: `/oms/portal/getOrganizationVoByid?id=${data.id}`,
    method: 'post',
  })
}

//需求新增
export function OmsDemand(data) {
  return request({
    url: `/oms/OmsDemand`,
    method: 'post',
    data
  })
}

//服务新增
export function OmsServiceRelease(data) {
  return request({
    url: `/oms/OmsServiceRelease`,
    method: 'post',
    data
  })
}

//获取品种类型
export function getServiceVarietys(data) {
  return request({
    url: '/oms/portal/getServiceVarietys',
    method: 'post',
    data
  })
}

//获取品种类型
export function getList(data) {
  return request({
    url: '/oms/portal/getList',
    method: 'post',
    data
  })
}

//获取栏目文章
export function itemArticleList(data) {
  return request({
    url: '/oms/portal/itemArticleList',
    method: 'post',
    data
  })
}

//获取栏目文章详情
export function getItemArticleContent(data) {
  return request({
    url: `/oms/portal/getItemArticleContent?id=${data.id}`,
    method: 'post',
  })
}

//友情链接
export function getLinkList() {
  return request({
    url: '/oms/portal/getLinkList',
    method: 'get',
  })
}

//反馈留言
export function CmsFeedback(data) {
  return request({
    url: '/oms/CmsFeedback',
    method: 'post',
    data
  })
}


//查询公司名称
export function getCompanyName(data) {
  return request({
    url: '/oms/company/list?name='+data.name,
    method: 'get',
  })
}
<template>
  <div class="sciencePopularization">
    <div class="searchContent">
      <el-input
        v-model="formData.searchValue"
        placeholder="请输入关键字"
      ></el-input>
      <div style="margin-top: 1px">
        <el-button
          @click="itemArticleList"
          icon="el-icon-search"
          class="el-button"
          >搜索</el-button
        >
      </div>
    </div>
    <div class="newsContent">
      <div class="tabs-content">
        <el-tabs v-model="formData.categoryId" @tab-click="handleClick">
          <el-tab-pane
            v-for="item in tabList"
            :key="item.id"
            :label="item.name"
            :name="item.id"
          ></el-tab-pane>
        </el-tabs>
      </div>
      <div class="picContent">
        <div class="leftContent">
          <!-- <div class="top-pic">
            <div
              class="tpImg-box"
              v-for="(item, index) in picList"
              :key="index"
              @click="openDetail(item)"
            >
              <img :src="optImage(item)" alt="" />
              <div class="tpImg-text">{{ item.title }}</div>
            </div>
          </div> -->
          <div class="bot-imgContent">
            <div
              v-for="(item, index) in dataList"
              :key="index"
              class="imgTextBox"
            >
              <img @click="openDetail(item)" :src="optImage(item)" alt="" />
              <div class="bot-message">
                <div @click="openDetail(item)" class="ms-title">
                  {{ item.title }}
                </div>
                <div class="ms-text1">
                  {{ item.title }}
                </div>
                <div class="ms-text2">
                  <span style="margin-right: 20px"
                    >发布时间：{{ item.createTime }}</span
                  >
                  <span>阅读：{{ item.hit }}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="pageContent">
            <el-pagination
              v-show="total > 0"
              @current-change="changePage"
              background
              layout="prev, pager, next"
              :total="total"
              :page-size="10"
              :current-page="formData.pageNum"
            >
            </el-pagination>
          </div>
        </div>
        <div class="rightContent">
          <div class="rct-Box">
            <div class="rc-title">热门科普</div>
          </div>
          <div class="pic-list">
            <div
              v-for="(item, index) in RpicList"
              :key="index"
              class="picL-box"
            >
              <img @click="openDetail(item)" :src="optImage(item)" alt="" />
              <div @click="openDetail(item)" class="picL-text">
                {{ item.title }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { itemArticleList, getList } from "@/api/serviechall";

export default {
  data() {
    return {
      formData: {
        categoryId: "0",
        pageNum: 1,
        pageSize: 10,
        params: {},
        searchValue: "",
        onlineStatus:1
      },
      total: 0,
      tabList: [],
      picList: [],
      dataList: [],
      RpicList: [],
    };
  },
  methods: {
    // getList() {
    //   let query = {
    //     itemId: 1,
    //     status: 1,
    //   };
    //   getList(query).then((res) => {
    //     const { code, rows } = res;
    //     if (code == 200) {
    //       this.tabList = [{ name: "全部", id: "0" }, ...rows];
    //     }
    //   });
    // },
    handleClick() {
      this.formData.pageNum = 1;
      this.itemArticleList();
    },
    openDetail(item) {
      let query = { id: item.id };
      let routeUrl = this.$router.resolve({
        path: "/portal/sciencePopularizationDetail",
        query,
      });
      window.open(routeUrl.href, "_blank");
    },
    changePage(page) {
      this.formData.pageNum = page;
      this.itemArticleList();
    },
    itemArticleList() {
      itemArticleList(this.formData).then((res) => {
        const { data, code } = res;
        if (code === 200) {
          const { hotArticleList, pageInfo, itemArticleType } = data;
          this.RpicList = hotArticleList;
          // this.picList = pageInfo.list.splice(0, 3);
          this.tabList = [{ name: "全部", id: "0" }, ...itemArticleType];
          this.dataList = pageInfo.list;
          this.total = pageInfo.total;
        }
      });
    },
    optTime(item) {
      const { createTime, updateTime } = item;
      let str = "";
      str =
        (createTime ? createTime.substring(0, 10) : "") +
        "~" +
        (updateTime ? updateTime.substring(0, 10) : "");
      return str;
    },
    optImage(item) {
      if (item.coverImage) {
        return item.coverImage.split(",")[0];
      }
    },
  },
  watch: {
    watchData: {
      deep: true,
      handler: function () {
        if (this.$store.state.formData.id == 5) {
          this.formData.searchValue = this.$store.state.formData.keyWord;
          this.itemArticleList();
        }
      },
      immediate: true,
    },
  },
  computed: {
    watchData() {
      return this.$store.state.formData;
    },
  },
  created() {
    this.formData.categoryId = this.$store.state.categoryId;
    this.itemArticleList();
  },
};
</script>
<style lang="scss" scoped>
.sciencePopularization {
  width: 1396px;
  margin: 0 auto;
  .searchContent {
    margin: 20px 0;
    display: flex;
    /deep/ .el-input,
    /deep/ .el-input__inner {
      outline: none;
      font-size: 14px;
      color: #999999;
      line-height: 40px;
      width: 140px;
      padding-left: 22px;
      background: none;
      height: 40px !important;
      border-radius: 62px 0 0 62px;
    }
    .el-button {
      height: 40px;
      border-radius: 0 62px 62px 0;
      background-color: #17a763;
      font-size: 14px;
      color: #fff;
      border: 1px solid #ddd;
    }
  }
  .newsContent {
    width: 100%;
    border: 1px solid #ddd;
    padding: 0 20px;

    .tabs-content {
      box-sizing: border-box;
      /deep/ .el-tabs__item {
        font-size: 22px;
        color: #333;
      }
      /deep/.el-tabs__item.is-active {
        font-weight: bold;
      }
      /deep/ .el-tabs__active-bar {
        background-color: #17a763;
      }
      /deep/ .el-tabs__nav {
        height: 48px;
        line-height: 48px;
      }
    }
  }
  .picContent {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 20px;
    .leftContent {
      width: 885px;
      .top-pic {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        width: 100%;
        justify-content: space-between;
        .tpImg-box {
          position: relative;
          .tpImg-text {
            width: 285px;
            height: 160px;
            text-align: center;
            position: absolute;
            bottom: 5px;
            height: 30px;
            line-height: 30px;
            width: 100%;
            background: rgba(0, 0, 0, 0.5);
            font-size: 12px;
            color: #ffffff;
            overflow: hidden;
          }
          img {
            width: 285px;
            height: 160px;
            border-radius: 5px;
          }
        }
      }
      .bot-imgContent {
        width: 100%;
        .imgTextBox {
          width: 100%;
          padding: 10px 0;
          border-bottom: 1px solid #ddd;
          display: flex;
          justify-content: space-between;
          img {
            width: 244px;
            height: 164px;
            border-radius: 5px;
          }
          .bot-message {
            width: 620px;
            color: #333;
            .ms-title {
              cursor: pointer;
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;
              font-size: 24px;
              font-weight: bold;
              margin-bottom: 10px;
            }
            .ms-title:hover {
              color: #17a763;
            }
            .ms-text1 {
              font-size: 16px;
              line-height: 28px;
              margin: 10px 0px;
              text-overflow: -o-ellipsis-lastline;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              line-clamp: 2;
              -webkit-box-orient: vertical;
            }
            .ms-text2 {
              display: flex;
              align-items: center;
              font-size: 16px;
              color: #868686;
            }
          }
        }
      }
      .pageContent {
        width: 100%;
        display: flex;
        justify-content: center;
        margin: 10px 0;
        /deep/ .el-pager li:not(.disabled).active {
          background-color: #17a763;
          color: #fff;
        }
      }
    }
    .rightContent {
      width: 420px;
      .rc-title {
        width: 107px;
        height: 48px;
        line-height: 48px;
        text-align: center;
        margin-right: 23px;
        font-size: 20px;
        color: #333;
        cursor: pointer;
        border-bottom: 3.5px solid #17a763;
      }
      .rct-Box {
        width: 100%;
        height: 49px;
        border-bottom: 1px solid #ddd;
      }
      .pic-list {
        width: 100%;
        padding: 15px;
        box-sizing: border-box;
        .picL-box {
          display: flex;
          justify-content: space-between;
          margin-bottom: 20px;
          width: 100%;
          img {
            width: 114px;
            height: 82px;
          }
          .picL-text {
            cursor: pointer;
            width: 245px;
            font-size: 14px;
            color: #333;
            text-overflow: -o-ellipsis-lastline;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            line-height: 28px;
            height: 50px;
          }
          .picL-text:hover {
            color: #17a763;
          }
        }
      }
    }
  }
}
</style>